import React from 'react';
import { Button, Checkbox, Grid, Typography } from '@mui/material';
import { green, grey, yellow } from '@mui/material/colors';

import { GuessLetterState, LetterPosition } from './util';
import { GetAPIURL, GenerateURI } from '../Common/query';

import ClearButton from '../Common/ClearButton';
import Guess from './Guess';
import Matches from './Matches';
import Misses from './Misses';
import Partials from './Partials';
import Results from './Results';
import UpdateButton from '../Common/UpdateButton';
import WordCount from './WordCount';
import WordLength from './WordLength';

export default class WordleApp extends React.Component {
  constructor(props) {
    super(props);

    this.matchInputRefs = Array(12);
    this.partialInputRefs = Array(12);
    this.guessInputRefs = Array(12);
    this.guessWordRef = React.createRef();
    this.guessButtonRefs = Array(12).fill(React.createRef());
    this.guessApplyRef = React.createRef();

    this.state = {
      wordLength: 5,
      currentMatch: null,
      currentPartial: null,
      matches: Array(12).fill(""),
      partials: this.defaultPartials(),
      misses: Array(26).fill("off"),
      guesses: Array(12).fill(""),
      guessWord: "",
      guessLoaded: false,
      results: null,
    };
  }

  componentDidMount() {
    this.matchInputRefs[0].current.focus();
  }

  fixMatchOrPartial(str) {
    return str.substring(str.length - 1).toUpperCase();
  }

  updateMisses(matches, partials) {
    const misses = this.state.misses.slice();

    for (let i = 0; i < 26; i++) {
      if (misses[i] === "match" || misses[i] === "partial") {
        misses[i] = "off";
      }
    }

    for (let i = 0; i < this.state.wordLength; i++) {
      if (matches[i] !== "") {
        misses[LetterPosition(matches[i])] = "match";
      }

      if (partials[i].chr !== "") {
        const position = LetterPosition(this.fixMatchOrPartial(partials[i].chr));
        if (misses[position] !== "match") {
          misses[position] = "partial";
        }
      }
    }

    return misses;
  }

  updatePartials(matches) {
    const partials = this.state.partials.slice();

    for (let i = 0; i < this.state.wordLength; i++) {
      if (matches[i] !== "") {
        for (let j = 0; j < this.state.wordLength; j++) {
          partials[j].positions[i] = false;
        }
      }
      if (partials[i].chr !== "") {
        partials[i].chr = this.fixMatchOrPartial(partials[i].chr);
      }
    }

    return partials;
  }

  handleMatchChange(match, evt) {
    const newLetter = this.fixMatchOrPartial(evt.target.value);

    // Put in the new match into the array.
    const matches = this.state.matches.slice();
    matches[match] = (newLetter >= "A" && newLetter <= "Z") ? newLetter : "";

    // Make sure that we reset the position markers and misses are updated if a new letter is present.
    const partials = this.updatePartials(matches);
    const misses = this.updateMisses(matches, partials);

    this.setState({
      matches: matches,
      partials: partials,
      misses: misses,
    });

    // Set the focus to the next match value if present.
    if (match < this.state.wordLength - 1) {
      this.matchInputRefs[match + 1].current.focus();
    } else {
      this.partialInputRefs[0].current.focus();
    }
  }

  handleMatchFocus(match, evt) {
    this.setState({ currentMatch: match });
  }

  handleMatchArrow(direction) {
    console.log("match = " + this.state.currentMatch + ", direction = " + direction);
    if (direction > 0) {
      if (this.state.currentMatch < this.state.wordLength - 1) {
        this.matchInputRefs[this.state.currentMatch + 1].current.focus();
      } else {
        this.partialInputRefs[0].current.focus();
      }
    } else {
      if (this.state.currentMatch > 0) {
        this.matchInputRefs[this.state.currentMatch - 1].current.focus();
      }
    }
  }

  handlePartialChange(partial, evt) {
    const partials = this.state.partials.slice();
    let updateFocus = false;
    if (evt.target.type === "checkbox") {
      if (this.state.matches[parseInt(evt.target.value)] === "") {
        partials[partial].positions[parseInt(evt.target.value)] = evt.target.checked;
      }
    } else {
      const newLetter = this.fixMatchOrPartial(evt.target.value);
      partials[partial].chr = (newLetter >= "A" && newLetter <= "Z") ? newLetter : "";
      updateFocus = true;
    }

    const misses = this.updateMisses(this.state.matches, partials);

    this.setState({
      partials: partials,
      misses: misses,
    });

    if (partial < this.state.wordLength - 1 && updateFocus) {
      this.partialInputRefs[partial + 1].current.focus();
    }
  }

  handlePartialFocus(partial, evt) {
    this.setState({ currentPartial: partial });
  }

  handlePartialArrow(direction) {
    if (direction > 0) {
      if (this.state.currentPartial < this.state.wordLength - 1) {
        this.partialInputRefs[this.state.currentPartial + 1].current.focus();
      }
    } else {
      if (this.state.currentPartial > 0) {
        this.partialInputRefs[this.state.currentPartial - 1].current.focus();
      } else {
        this.matchInputRefs[this.state.wordLength - 1].current.focus();
      }
    }
  }

  handleWordLengthChange(evt) {
    this.setState({
      wordLength: parseInt(evt.target.value),
    });

    this.matchInputRefs[0].current.focus();
  }

  handleMissChange(letter, evt) {
    const misses = this.state.misses.slice();
    const position = LetterPosition(letter);

    if (misses[position] === "on") {
      misses[position] = "off";
    } else {
      if (misses[position] === "off") {
        misses[position] = "on";
      }
    }

    this.setState({
      misses: misses,
    });
  }

  defaultPartials() {
    // Default in the partials array.
    let partials = Array(12);
    for (let i = 0; i < 12; i++) {
      partials[i] = {
        chr: "",
        positions: Array(12).fill(false),
      };
    }

    return partials;
  }

  handleClearClick(evt) {
    this.setState({
      wordLength: this.state.wordLength,
      matches: Array(12).fill(""),
      partials: this.defaultPartials(),
      misses: Array(26).fill("off"),
      guesses: Array(12).fill(""),
      guessWord: "",
      guessLoaded: false,
      results: null,
    });
  }

  handleUpdateClick(evt) {
    this.setState({
      results: "loading...",
    });

    this.getResults();
  }

  handleClearMatches(evt) {
    const matches = Array(12).fill("");
    const partials = this.updatePartials(matches);
    const misses = this.updateMisses(matches, partials);

    this.setState({
      matches: matches,
      partials: partials,
      misses: misses,
    });
  }

  handleClearPartials(evt) {
    const partials = this.defaultPartials();
    const misses = this.updateMisses(this.state.matches, partials);

    this.setState({
      partials: partials,
      misses: misses,
    });
  }

  handleClearInvalid(evt) {
    const misses = this.state.misses;

    for (let i = 0; i < 26; i++) {
      if (misses[i] !== "match" && misses[i] !== "partial") {
        misses[i] = "off";
      }
    }

    this.setState({
      misses: misses,
    });
  }

  handleGuessWordChange(evt) {
    this.setState({ guessWord: evt.target.value });
  }

  // Handle the guess word "Load!" button action.
  handleGuessButtonClick(evt) {
    const guessLetters = this.guessWordRef.current.value.toUpperCase().substring(0, this.state.wordLength).replace(/[^A-Z]/, "");

    var guesses = Array(12).fill("");
    for (var i = 0; i < this.state.wordLength; i++) {
      guesses[i] = GuessLetterState(i, guessLetters.charAt(i), this.state.matches, this.state.partials, this.state.misses);
    }

    this.setState({
      guessWord: guessLetters,
      guesses: guesses,
      guessLoaded: true,
    });
  }

  // Take the current state of the guess word buttons and apply it to the rest of the app.
  handleGuessApplyClick(evt) {
    if (this.state.guessLoaded) {
      var matches = this.state.matches.slice();
      var partials = this.state.partials.slice();
      var misses = this.state.misses.slice();

      for (var i = 0; i < this.state.wordLength; i++) {
        var letter = this.state.guessWord.charAt(i).toUpperCase();
        var position = LetterPosition(letter);

        switch (this.state.guesses[i]) {
          case "match":
            if (matches[i] === "") {
              matches[i] = letter;
              misses[position] = "match";
            }
            break;

          case "partial":
            // Find if we have an existing partial letter that matches and update the position indicator.

            var found = false;
            for (var j = 0; j < this.state.wordLength; j++) {
              if (partials[j].chr === letter) {
                found = true;
                partials[j].positions[i] = true;
                if (misses[position] !== "match") {
                  misses[position] = "partial";
                }
              }
            }
            if (!found) {
              // Otherwise, we need to find the first unused partial match slot and set that with the detail from the button state.

              var index = 0;
              while (index < this.state.wordLength && partials[index].chr !== "") {
                index++;
              }
              partials[index].chr = letter;
              partials[index].positions[i] = true;
              if (misses[position] !== "match") {
                misses[position] = "partial";
              }
            }
            break;

          case "off":
          default:
            if (misses[LetterPosition(letter)] === "off") {
              misses[LetterPosition(letter)] = "on";
            } else {
              for (var k = 0; k < this.state.wordLength; k++) {
                if (partials[k].chr === letter) {
                  partials[k].positions[i] = true;
                }
              }
            }
            break;
        }
      }

      this.setState({
        matches: matches,
        partials: partials,
        misses: misses,
        guesses: Array(12).fill(""),
        guessWord: "",
        guessLoaded: false,
      });

      this.setState({
        partials: this.updatePartials(matches),
      });
    }
  }

  handleGuessLetterClick(letter, index, evt) {
    if (this.state.guessLoaded) {
      // Cycle through the various states of a guess word letter.

      var guesses = this.state.guesses;
      if (guesses[index] === "") {
        guesses[index] = "partial";
      } else {
        if (guesses[index] === "miss") {
          guesses[index] = "partial";
        } else {
          if (guesses[index] === "partial") {
            guesses[index] = "match";
          } else {
            if (this.state.matches[index] !== letter) {
              guesses[index] = "miss";
            }
          }
        }
      }

      this.setState({
        guesses: guesses,
      });

      this.guessButtonRefs[index].current.blur();
      this.guessApplyRef.current.focus();
    }
  }

  async getResults() {
    const URL = GetAPIURL() + "/word-list/" + this.state.wordLength + "?" + GenerateURI(this.state);

    console.log("url = " + URL);
    const response = await fetch(URL, { });
    const data = await response.json();
    this.setState({
      results: data,
    });
  }

  render() {
    const greyStyle = {
      width: "25px",
      textAlign: "center",
      border: "1px solid #000",
      backgroundColor: grey["50"],
    };
    const greenStyle = {
      width: "25px",
      textAlign: "center",
      border: "1px solid #000",
      backgroundColor: green["300"],
    };
    const yellowStyle = {
      width: "25px",
      textAlign: "center",
      border: "1px solid #000",
      backgroundColor: yellow["300"],
    };
    const yellowStyleNoBox = {
      width: "45px",
      textAlign: "center",
      backgroundColor: yellow["300"],
    };
    const missStyleGreen={
      width: "30px",
      height: "25px",
      textAlign: "center",
      color: grey["900"],
      border: "1px solid #000",
      backgroundColor: green["300"],
    };
    const missStyleEmpty={
      width: "30px",
      height: "25px",
      textAlign: "center",
      color: grey["900"],
      border: "1px solid #000",
      backgroundColor: grey["50"],
    };
    const missStyleGrey={
      width: "30px",
      height: "25px",
      textAlign: "center",
      color: grey["900"],
      border: "1px solid #000",
      backgroundColor: grey["400"],
    };

    return (
      <>
        <br />
        <WordLength value={this.state.wordLength} onChange={(evt) => this.handleWordLengthChange(evt)} />
        <hr />
        <Guess inputRef={this.guessWordRef} parentHandle={this} guessWord={this.state.guessWord} wordLength={this.state.wordLength} guesses={this.state.guesses} guessLoaded={this.state.guessLoaded} guessButtonRefs={this.guessButtonRefs} guessApplyRef={this.guessApplyRef} onWordChange={this.handleGuessWordChange} onButtonClick={this.handleGuessButtonClick} onLetterClick={this.handleGuessLetterClick} onApplyClick={this.handleGuessApplyClick} guessInputRefs={this.guessInputRefs} />
        <hr />
        <Matches parentHandle={this} matches={this.state.matches} wordLength={this.state.wordLength} handleClearMatches={this.handleClearMatches} handleMatchChange={this.handleMatchChange} matchInputRefs={this.matchInputRefs} />
        <hr />
        <Partials parentHandle={this} partials={this.state.partials} wordLength={this.state.wordLength} handleClearPartials={this.handleClearPartials} handlePartialChange={this.handlePartialChange} partialInputRefs={this.partialInputRefs} />
        <hr />
        <Misses parentHandle={this} misses={this.state.misses} handleMissChange={this.handleMissChange} handleClearInvalid={this.handleClearInvalid} />
        <hr />
        <Grid container>
          <Grid item xs={3} justifyContent="right">Word Count:</Grid>
          <Grid item xs={3} justifyContent="right"><WordCount value={this.state} /></Grid>
        </Grid>
        <hr />

        <Grid container>
          <Grid item xs={3}>
            <ClearButton value="" onClick={(evt) => this.handleClearClick(evt)} />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={3}>
            <UpdateButton value="" onClick={(evt) => this.handleUpdateClick(evt)} />
          </Grid>
        </Grid>
        <Results parentState={this.state} value={this.state.results} />
        <br />
        <hr />
        <Typography variant="body1">
          <b>Wordle!</b> style puzzles generally consist of guessing words of a fixed length.  The game will tell the player which letters are correct in the correct position, which letters are correct but not in the correct position, and which letters are not correct.  Each guess generally requires using a valid word of the appropriate length.
        </Typography>
        <br />
        <Typography variant="body1">
          <b>Wordle!</b> was invented by a software engineer named Josh Wardle.  The game is currently owned by The New York Times since early 2022.  It was originally made public in 2021.  Today there are many variants of the game available on different mobile platforms and the web.
        </Typography>
        <br />
        <Typography variant="body1">
          There are two ways to enter guesses -- they can be used together at the same time.  The first is to enter the word you guessed into the box at the top and click the <tt>Load!</tt> button.  That will let you select which letters were partial or exact matches and then click the <tt>Apply!</tt> button to move those guessed letter states into the form below.  Once the word has been loaded into the buttons below the guess word box you can click on each letter to change its state from an <span style={{ backgroundColor: green["300"] }}>exact match</span>, a <span style={{ backgroundColor: yellow["300"] }}>valid letter</span>, or a <span style={{ backgroundColor: grey["50"] }}>miss</span>.  This will change the color of the letter button to match the colors used below.  This can be a very fast way to get <span style={{ backgroundColor: green["300"] }}>matches</span>, <span style={{ backgroundColor: yellow["300"] }}>valid letters</span>, and <span style={{ backgroundColor: grey["50"] }}>invalid letters</span> into the rest of the form quickly.
        </Typography>
        <br />
        <Typography variant="body1">
          The second way is to use the rest of the form to enter exact matches, valid letters, and missed letters into the form on a per letter basis.  This is described in more detail below.
        </Typography>
        <br />
        <Typography variant="body1">
          In the boxes above, after you select the size of the word to be guessed, you can enter the exact matches, valid letters, and invalid letters.  For the valid letters you can also optionally indicate the locations in the word where the letters should not be found.  This can further restrict the provided words to only those which could be solutions to the puzzle.
        </Typography>
        <br />
        <hr />
        <Typography variant="h3">
          How to Use This Helper
        </Typography>
        <Typography variant="body1">
          For example, let's say you have a five letter game.  In this game, you'll create a five letter word guess each turn.  If you guess the word <tt>GUESS</tt>, for example, and the game comes back and shows you the following, we can look at how we can use this information to help you narrow down your next guess.
        </Typography>
        <br />
        <Button variant="text">
          <Typography sx={greyStyle} noWrap>G</Typography>
          <Typography sx={greenStyle} noWrap>U</Typography>
          <Typography sx={greyStyle} noWrap>E</Typography>
          <Typography sx={yellowStyle} noWrap>S</Typography>
          <Typography sx={greyStyle} noWrap>S</Typography>
        </Button>
        <br />
        <br />
        <Typography variant="body1">
          What this guess tells you is that the word you are looking for has no <tt>G</tt> or <tt>E</tt>.  Moreover, there is a <tt>U</tt> as the second letter.  Next, there is an <tt>S</tt> in the target word but it isn't the fourth letter.  There is one more, much more subtle, hint in the response from the game.  Because the last <tt>S</tt> is neither yellow nor green you know that there is only one <tt>S</tt> in the answer.
        </Typography>
        <br />
        <Typography variant="body1">
          To enter this information into the helper above, first make sure that the word length is set to 5 letters.  Next, you would enter <tt>U</tt> into the second box in the <b>Exact Matches</b> section above.
        </Typography>
        <br />
        <Grid container>
          <Grid item xs={1}>&nbsp;</Grid>
          <Grid item xs={11}>
            <Button variant="text">
              <Typography sx={greyStyle} noWrap>&nbsp;</Typography>
              <Typography sx={greenStyle} noWrap>U</Typography>
              <Typography sx={greyStyle} noWrap>&nbsp;</Typography>
              <Typography sx={greyStyle} noWrap>&nbsp;</Typography>
              <Typography sx={greyStyle} noWrap>&nbsp;</Typography>
            </Button>
          </Grid>
        </Grid>
        <br />
        <Typography variant="body1">
         Since you know the word you are looking for has neither a <tt>G</tt> nor an <tt>E</tt>, you can click the buttons for those two letters in the <b>Invalid</b> section of the form.  In this case, the invalid letters do not appear in the word.
        </Typography>
        <br />
        <Grid container>
          <Grid item container xs={12} wrap="nowrap" justifyContent="center" alignItems="center">
            <Typography variant="body2" sx={missStyleEmpty}>Q</Typography>
            <Typography variant="body2" sx={missStyleEmpty}>W</Typography>
            <Typography variant="body2" sx={missStyleGrey}>E</Typography>
            <Typography variant="body2" sx={missStyleEmpty}>R</Typography>
            <Typography variant="body2" sx={missStyleEmpty}>T</Typography>
            <Typography variant="body2" sx={missStyleEmpty}>Y</Typography>
            <Typography variant="body2" sx={missStyleGreen}>U</Typography>
            <Typography variant="body2" sx={missStyleEmpty}>I</Typography>
            <Typography variant="body2" sx={missStyleEmpty}>O</Typography>
            <Typography variant="body2" sx={missStyleEmpty}>P</Typography>
          </Grid>
          <Grid item container xs={12} wrap="nowrap" justifyContent="center" alignItems="center">
            <Typography variant="body2" sx={missStyleEmpty}>A</Typography>
            <Typography variant="body2" sx={missStyleEmpty}>S</Typography>
            <Typography variant="body2" sx={missStyleEmpty}>D</Typography>
            <Typography variant="body2" sx={missStyleEmpty}>F</Typography>
            <Typography variant="body2" sx={missStyleGrey}>G</Typography>
            <Typography variant="body2" sx={missStyleEmpty}>H</Typography>
            <Typography variant="body2" sx={missStyleEmpty}>J</Typography>
            <Typography variant="body2" sx={missStyleEmpty}>K</Typography>
            <Typography variant="body2" sx={missStyleEmpty}>L</Typography>
          </Grid>
          <Grid item container xs={12} wrap="nowrap" justifyContent="center" alignItems="center">
            <Typography variant="body2" sx={missStyleEmpty}>Z</Typography>
            <Typography variant="body2" sx={missStyleEmpty}>X</Typography>
            <Typography variant="body2" sx={missStyleEmpty}>C</Typography>
            <Typography variant="body2" sx={missStyleEmpty}>V</Typography>
            <Typography variant="body2" sx={missStyleEmpty}>B</Typography>
            <Typography variant="body2" sx={missStyleEmpty}>N</Typography>
            <Typography variant="body2" sx={missStyleEmpty}>M</Typography>
          </Grid>
        </Grid>
        <br />
        <Typography variant="body1">
          To enter the partial match, <tt>S</tt>, you can just put an <tt>S</tt> in the <b>Valid Letters</b> section.  To be more precise and let the helper know that <tt>S</tt> should not be the fourth letter, click the fourth checkbox in the row where you put the <tt>S</tt>.
        </Typography>
        <br />
        <Grid container>
          <Grid item xs={1}>
            <Typography sx={yellowStyle} noWrap>D</Typography>
          </Grid>
          <Grid item xs={11}>
            <Checkbox sx={yellowStyleNoBox} />
            <Checkbox sx={yellowStyleNoBox} />
            <Checkbox sx={yellowStyleNoBox} />
            <Checkbox sx={yellowStyleNoBox} checked={true} />
            <Checkbox sx={yellowStyleNoBox} />
          </Grid>
        </Grid>
        <br />
        <Typography variant="body1">
          Once this has been put in the form above the page will tell you that there are <tt>338</tt> matching words.  If you click on the <b>Show Words!</b> button then those words will show up.  Choose a word from the list to make your next guess in the game.  Based on what the game tells you about your next guess you can update the form above to further refine the list of possible solutions.
        </Typography>
        <br />
        <Typography variant="body1">
          If you guess <tt>DUSKY</tt> as your next choice, the game might come back with the following:
        </Typography>
        <br />
        <Button variant="text">
          <Typography sx={greyStyle} noWrap>D</Typography>
          <Typography sx={greenStyle} noWrap>U</Typography>
          <Typography sx={greenStyle} noWrap>S</Typography>
          <Typography sx={greyStyle} noWrap>K</Typography>
          <Typography sx={greenStyle} noWrap>Y</Typography>
        </Button>
        <br />
        <br />
        <Typography variant="body1">
          Put the <tt>S</tt> in the third and <tt>Y</tt> in the fifth boxes in the <b>Exact Matches</b> section.  You can erase the <tt>S</tt> from the <b>Valid Letters</b> section (you can also but don't have to remove the check mark from the fourth box).  The web page will now show you that there are <tt>22</tt> words that match this pattern.  This is a much more reasonable list to use to further refine your next guess.  Morever, because you know there is only one <tt>S</tt> in the target word, you can eliminate five of the 22 suggested words since they contain <tt>S</tt>'s in them.  That leaves 17 potential solutions to the puzzle.
        </Typography>
        <br />
        <Typography variant="body1">
          All of the words give to you that match what you put in the form can be clicked to bring up their definition.  In rare occasions the words won't have a definition available in our backend.  However, there will always be a web link to let you do a search on the Internet for the definition of some of the more obscure words.
        </Typography>
        <br />
        <Typography variant="body1">
          At any time you can use one of the <b>Clear</b> button's to help quickly remove values from different sections.  The <b>Clear</b> button in the <b>Exact Matches</b> section will only remove the exact match values you've entered.  The <b>Clear</b> button in the <b>Valid Letters</b> section will only remove values (both letters entered and checks in the boxes) from that section.  To reset everything in the form you can use the <b>Clear Form!</b> button at the bottom.  This will clear the <b>Exact Matches</b>, <b>Valid Letters</b>, and <b>Invalid</b> values.  It will not, though, change the valid for the length of the word being guessed.  If you want to change the length of the word you'll have to change this value separately.
        </Typography>
      </>
    );
  }
}

