export function LetterPosition(letter) {
  return parseInt(letter.charCodeAt(0)) - parseInt("A".charCodeAt(0));
}

export function GuessLetterState(position, letter, matches, partials, misses) {
  if (matches[position] !== "") {
    return (letter === matches[position] || letter === "") ? "match" : "error";
  }

  for (var i = 0; i < partials.length; i++) {
    if (partials[i].chr !== "" && partials[i].chr === letter) {
      return (letter === partials[i].chr || letter === "") ? "partial" : "error";
    }
  }

  if (letter !== "") {
    return (misses[LetterPosition(letter.toUpperCase())] === "on") ? "miss" : "";
  }

  return "";
}

export default function util(txt) {}

